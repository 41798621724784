<template>
  <component
    :is="componentName(element)"
    v-for="element in page.elements"
    :key="element.id"
    :element="element"
    :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
  />
</template>

<script>
  import { AlchemyPage } from "~/mixins/alchemyVue"
  import { useNotificationStore } from "~/pinia/notifications"
  const NARROW_ELEMENTS = ["text_block", "headline"]

  export default {
    components: {
      articles: defineAsyncComponent(
        () => import("~/alchemy/elements/articles"),
      ),
      curalate_embed: defineAsyncComponent(
        () => import("~/alchemy/elements/curalate_embed"),
      ),
      divider: defineAsyncComponent(() => import("~/components/Divider")),
      email_signup: defineAsyncComponent(
        () => import("~/alchemy/elements/email_signup"),
      ),
      headline: defineAsyncComponent(
        () => import("~/alchemy/elements/headline"),
      ),
      image_full_width: defineAsyncComponent(
        () => import("~/alchemy/elements/image_full_width"),
      ),
      main_header: defineAsyncComponent(
        () => import("~/alchemy/elements/header"),
      ),
      masthead: defineAsyncComponent(
        () => import("~/alchemy/elements/masthead"),
      ),
      section_with_background: defineAsyncComponent(
        () => import("~/alchemy/elements/section_with_background"),
      ),
      sub_header: defineAsyncComponent(
        () => import("~/alchemy/elements/header"),
      ),
      text_block: defineAsyncComponent(
        () => import("~/alchemy/elements/text_block"),
      ),
      grid_columns: defineAsyncComponent(
        () => import("~/alchemy/elements/grid_columns"),
      ),
      wistia_embed: defineAsyncComponent(
        () => import("~/alchemy/elements/wistia_embed"),
      ),
    },
    mixins: [AlchemyPage],
    setup(props) {
      useHead(usePageInfo(props.page))
      const notificationStore = useNotificationStore()
      onMounted(() => {
        notificationStore.announce(props.page.title)
      })
    },
    data() {
      return {
        NARROW_ELEMENTS,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
